import React from "react";

import { Link } from "gatsby";
import { Menu } from "antd";

export const InteriorProductsMenu = ({ activePage }) => (
  <div id="ccProductWrapper" className="productMenuWrapper">
    <Menu
      onClick={() => console.log("whatever")}
      selectedKeys={[activePage]}
      mode="horizontal"
    >
      <Menu.Item key="thermoPlus" className="thermoPlus">
        <Link to="/productos/thermoplus/"> ThermoPlus</Link>
      </Menu.Item>

      <Menu.Item key="lumen" className="lumen">
        <Link to="/productos/lumen/"> Lumen</Link>
      </Menu.Item>
    </Menu>
  </div>
);

export default InteriorProductsMenu;
