import React from "react";

import { List } from "antd";

import lumenGaraje from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-garaje.png";
import lumenStudy from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-estudio.png";
import lumenHotel from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-hotel.png";
import lumenHouse from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-hogar.png";

import "src/components/products/listContentBlocks/style.scss";

export const LumenApplicationsMoreListContentBlock = (props) => (
  <>
    <h2>Mayor generación de luz...</h2>
    <List
      {...{
        className: "listContentBlockWrapper",
        grid: {
          gutter: 40,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 4,
          xxl: 4,
        },
        dataSource: [
          {
            imgSrc: lumenGaraje,
            text: "…en parkings subterráneos",
          },
          {
            imgSrc: lumenStudy,
            text: "…en estudios y talleres",
          },
          {
            imgSrc: lumenHotel,
            text: "…en hoteles y oficinas",
          },
          {
            imgSrc: lumenHouse,
            text: "…y en casa",
          },
        ],
        renderItem: (item) => (
          <List.Item className="listContentBlock imageWithList">
            <img className={"blockImage "} src={item.imgSrc} />
            <div className="centered marginTop10">{item.text}</div>
          </List.Item>
        ),
      }}
    />
  </>
);

export default LumenApplicationsMoreListContentBlock;
