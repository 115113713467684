import React from "react";

export const LumenApplicationFeatures = () => (
  <>
    <h2 className="sectionTitle centerAligned">
      Campo de aplicación, características y aplicación.{" "}
    </h2>
    <p>
      Lumen se utiliza cuando se requiere una intensidad lumínica
      particularmente buena. Este duradero revestimiento es ideal para oficinas,
      estudios, escuelas, naves industriales, talleres, museos así como zonas
      con poca luminosidad como garajes subterráneos y metros. Y por supuesto,
      también para el hogar.
    </p>
  </>
);

export default LumenApplicationFeatures;
