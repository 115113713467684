import React from "react";

import { List } from "antd";

import lumenApplication from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-application.png";
import lumenColors from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-colors.png";
import lumenMetro from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-metro.png";
import lumenMuseo from "src/components/products/listContentBlocks/lumen/images/climateCoating-Lumen-museo.png";

import "src/components/products/listContentBlocks/style.scss";

export const LumenApplicationsListContentBlock = (props) => (
  <>
    <List
      {...{
        className: "listContentBlockWrapper",
        grid: {
          gutter: 40,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 2,
          xl: 4,
          xxl: 4,
        },
        dataSource: [
          {
            imgSrc: lumenApplication,
            text: "Seleccione tonos de color",
          },
          {
            imgSrc: lumenColors,
            text: "Pinta, utiliza el rodillo, pulveriza",
          },
          {
            imgSrc: lumenMetro,
            text: "Incrementa el brillo en el metro",
          },
          {
            imgSrc: lumenMuseo,
            text: "Embellece las paredes de los museos",
          },
        ],
        renderItem: (item) => (
          <List.Item className="listContentBlock imageWithList">
            <img className={"blockImage "} src={item.imgSrc} />
            <div className="centered marginTop10">{item.text}</div>
          </List.Item>
        ),
      }}
    />
  </>
);

export default LumenApplicationsListContentBlock;
