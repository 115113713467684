import React from "react";

import lumenImage from "src/components/products/images/lumen.jpg";
import { Link } from "gatsby";
import { Row, Col, List } from "antd";
import {
  FilePptOutlined,
  FileProtectOutlined,
  SolutionOutlined,
  CheckOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import "src/components/products/imgAndIntro/style.scss";

export const LumenImgAndIntro = (props) => (
  <div className="imgAndIntro">
    <div className="productImageLinksBlock">
      <img src={lumenImage} />
      <Row className="productLinks">
        <Col span="8">
          <span className="linkIcon">
            <FilePptOutlined />
          </span>
          <span className="linkText">Presentación</span>

          <span id="productPresentation" className="downloadIcon">
            <a href="/lumen/climateCoating-lumen-presentacion.pdf" download>
              <DownloadOutlined />
            </a>
          </span>
        </Col>

        <Col span="8">
          {/* 
          <Link to="/fichas-tecnicas/thermoactive">
            <span className="linkIcon">
              <FileProtectOutlined />
            </span>
            <span className="linkText">Ficha Técnica</span>
          </Link>
          <span id="productFichaTecnica" className="downloadIcon">
            <a href="/lumen/climateCoating-lumen-ficha-tecnica.pdf" download>
              <DownloadOutlined />
            </a>
          </span>
          */}
        </Col>
        <Col span="8">
          {/*
          <Link to="/fichas-de-aplicacion/thermoactive">
            <span className="linkIcon">
              <SolutionOutlined />
            </span>
            <span className="linkText">Ficha de Aplicación</span>
          </Link>
          <span id="productFichaAplicacion" className="downloadIcon">
            <a
              href="/lumen/climateCoating-lumen-ficha-de-aplicacion.pdf"
              download
            >
              <DownloadOutlined />
            </a>
          </span>

        */}
        </Col>
      </Row>{" "}
    </div>
    <h1>
      <span className="paddingRight10"></span>Lumen
    </h1>
    <p>
      <strong>ClimateCoating Lumen</strong> es un revestimiento altamente
      efectivo para paredes y techos interiores, que ofrece un mejor rendi-
      miento lumínico que las pinturas convencionales incluso con colores
      oscuros. Las diferencias de contraste se suavizan y proporciona un
      equilibrio en la distribución de la luz.
      <br />
      <br />
      Al aumentar significativamente la reflexión de la luz difusa, el
      revestimiento proporciona una mejor iluminación de la habitación
    </p>

    <ul className="productFeatureList">
      <li>
        <CheckOutlined />
        mayor confort lumínico
      </li>
      <li>
        <CheckOutlined />
        mayor bienestar
      </li>
      <li>
        <CheckOutlined />
        menor consumo de energía
      </li>
      <li>
        <CheckOutlined />
        conservación de recursos
      </li>
    </ul>
  </div>
);

export default LumenImgAndIntro;
