import React from "react";

import { List } from "antd";

import "src/components/products/listContentBlocks/style.scss";

export const LumenListContentBlock = (props) => (
  <List
    {...{
      className: "listContentBlockWrapper",
      grid: {
        gutter: 20,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 2,
        xxl: 2,
      },
      dataSource: [
        {
          content: `Estudios independientes demuestran que la medición real del valor de reflexión difusa de Lumen en cada tono es significativamente más alta que los valores predeterminados utilizados en programas profesionales de cálculo de iluminación como Dialux.`,
        },
        {
          content: `Millones de microesferas termocerámicas
            mejoran el valor de reflexión difusa un 6% para el
            color blanco y hasta un 420% para el color negro.
            Los arquitectos y diseñadores ahora pueden usar
            colores oscuros y matices de forma más creativa.
            Lumen da literalmente luz y color a los interiores
            contemporáneos.`,
        },
      ],
      renderItem: (item) => (
        <List.Item className="listContentBlock">
          <p>{item.content}</p>
        </List.Item>
      ),
    }}
  />
);

export default LumenListContentBlock;
