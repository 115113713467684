import React from "react";

import { List, Card } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import "src/components/products/listPropertyCards/style.scss";

export const LumenListPropertyCard = (props) => (
  <List
    {...{
      grid: {
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      },

      dataSource: [
        {
          title: "Innovador",
          listProps: [
            "altísima reflexión de luz",
            "mejor dispersión de luz incluso con colores oscuros",
          ],
          subTitle: "La membrana reflectante de Lumen proporciona:",
          subListProps: [
            "una difusión de vapor variable",
            "una regulación de la  humedad",
            "repele la suciedad y la nicotina",
            "una mejora del clima interior",
          ],
        },
        {
          title: "Respetuoso con el Medio Ambiente",
          listProps: [
            "en base de agua",
            "inoloro",
            "valor de COV muy bajo",
            "libre de compuestos aromáticos",
            "sin disolventes orgánicos",
          ],
        },

        {
          title: "Decorativo",
          listProps: [
            "para interior",
            "mate",
            "alto poder de cobertura",
            "más de 100.000 tonos de color para escoger",
            "muy alta estabilidad del color",
          ],
        },
        {
          title: "Dimensiones de Envases",
          listProps: [
            "dispersión lista para usar",
            "5,0 / 12,5 / 19,0 litros en envase ovalado",
            "consumo (dependiendo sobre soporte) aprox. 330ml / m2 con 2 manos",
            "fácil aplicación con brocha, método de rodillo o pulverización",
            "las herramientas utilizadas se deben limpiar con agua",
          ],
        },
      ],
      renderItem: (item) => (
        <List.Item>
          <Card className="propertyCard">
            <span className="propertyTitle">{item.title}</span>
            <ul className="propertyCardList">
              {item.listProps.map((prop) => (
                <li>
                  <CheckOutlined />
                  {prop}
                </li>
              ))}
            </ul>
            {item.subTitle && (
              <>
                <span className="propertyTitle">{item.subTitle}</span>
                <ul className="propertyCardList">
                  {item?.subListProps.map((prop) => (
                    <li>
                      <CheckOutlined />
                      {prop}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </Card>
        </List.Item>
      ),
    }}
  />
);

export default LumenListPropertyCard;
