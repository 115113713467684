import React from "react";

import ReflexionDifusaLumenImg from "src/components/products/lumen/climateCoating-lumne-valores-reflexion-difusa.png";

import "src/components/products/howDoesItWorkImgBottom/style.scss";

export const LumenReflexionDifusaHowDoesItWork = (props) => (
  <div className="howDoesItWorkImgBottom">
    <h3>Valores de Reflexión Difusa con Lumen</h3>
    <p>
      En abril de 2016, la empresa holandesa Meutzer Light Vision llevó a cabo
      varias mediciones con respecto a valores de reflexión difusa (Rd), entre
      otros, el espectro de luz
    </p>
    <img src={ReflexionDifusaLumenImg} />
  </div>
);

export default LumenReflexionDifusaHowDoesItWork;
