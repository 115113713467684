import React from "react";

export const BrightLightHighligtedBlock = (props) => (
  <div
    className="hightlighted"
    style={{ marginTop: "45px", marginBottom: "50px" }}
  >
    <h3>
      Lumen proporciona un mejor equilibrio del brillo y una luminosidad más
      intensa y eficaz en las habitaciones, incluso si se trata de colores
      oscuros.
    </h3>
  </div>
);

export default BrightLightHighligtedBlock;
