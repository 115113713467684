import { Content } from "antd/lib/layout/layout";
import React from "react";

import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { Divider } from "antd";

import LumenImgAndIntro from "src/components/products/imgAndIntro/Lumen";
import BrightLightHighligtedBlock from "src/components/products/blocks/highlighted/BrightLight";
import LumenListContentBlock from "src/components/products/listContentBlocks/lumen/Lumen";

import LumenReflexionDifusaHowDoesItWork from "src/components/products/howDoesItWorkImgBottom/LumenEnsayoReflexionDifusa";
import LumenApplicationFeatures from "src/components/products/blocks/simpleText/LumenApplication";
import LumenListPropertyCard from "src/components/products/listPropertyCards/Lumen";
import InteriorProductsMenu from "src/menus/product/Interior";
import LumenApplicationsListContentBlock from "src/components/products/listContentBlocks/lumen/Applications";
import LumenApplicationsMoreListContentBlock from "src/components/products/listContentBlocks/lumen/ApplicationsMore";

import "src/components/products/style.scss";
import "src/components/products/lumen/style.scss";

const Lumen = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      productName: "lumen",
      pageTitle: "ClimateCoating Lumen",
      pageName: "Lumen",
      pageDescription:
        "ClimateCoating Lumen: Membrana Termocerámica con redimiento endotérmico para interiores con riesgo de humedades",
    }}
  >
    {/*
    <CarouselReferencias {...{ maxWidth: "100%", slidesToShow: 4 }} />
*/}
    <InteriorProductsMenu {...{ activePage: "lumen" }} />

    <Content className="product lumen">
      <LumenImgAndIntro />

      <BrightLightHighligtedBlock />

      <LumenListContentBlock />

      <LumenReflexionDifusaHowDoesItWork />

      <br />
      <br />

      <LumenApplicationFeatures />

      <div className="clearBoth marginTopBottom20" />

      <LumenListPropertyCard />

      <LumenApplicationsListContentBlock />

      <LumenApplicationsMoreListContentBlock />
    </Content>
  </LayoutGeneral>
);

export default Lumen;
